import React, { useState } from "react";
import { Row, Col, Card, Radio, Typography, Tooltip, Calendar, Tag, Modal, Table, Alert } from "antd";
import Wrapper from "../../components/layout/wrapper";
import GaugeChart from "react-gauge-chart";
import HeatMap from "@uiw/react-heat-map";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import "dayjs/locale/tr";

dayjs.locale("tr");

const Dashboard: React.FC = () => {
  const [period, setPeriod] = useState<"daily" | "monthly" | "yearly">("daily");
  const [heatmapYear, setHeatmapYear] = useState<"thisYear" | "lastYear">("thisYear");
  const [chartType, setChartType] = useState<"line" | "bar">("line");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [isGaugeModalOpen, setIsGaugeModalOpen] = useState(false);
  const [gaugeModalData, setGaugeModalData] = useState<any[]>([]);

  const gaugeData = {
    hedef: 100,
    sonuc: 75,
  };

  const dataDetailTable = [
    { key: "1", hedef: 10, sonuc: 0, basari: "0%" },
    { key: "2", hedef: 12, sonuc: 0, basari: "0%" },
    { key: "3", hedef: 14, sonuc: 0, basari: "0%" },
    { key: "4", hedef: 5, sonuc: 0, basari: "0%" },
    { key: "5", hedef: 12, sonuc: 0, basari: "0%" },
  ];

  const columnsDetailTable = [
    { title: "Hedef", dataIndex: "hedef", key: "hedef" },
    { title: "Sonuç", dataIndex: "sonuc", key: "sonuc" },
    { title: "Başarı Yüzdesi", dataIndex: "basari", key: "basari" },
  ];

  const showGaugeModal = () => {
    setGaugeModalData(dataDetailTable);
    setIsGaugeModalOpen(true);
  };

  const closeGaugeModal = () => {
    setIsGaugeModalOpen(false);
    setGaugeModalData([]);
  };

  const generateCalendarData = () => {
    const today = dayjs();
    return Array.from({ length: 60 }, (_, i) => {
      const date = today.subtract(i, "day").format("YYYY-MM-DD");
      if (Math.random() > 0.3) {
        const hedef = Math.floor(Math.random() * 30) + 20;
        const sonuc = Math.floor(Math.random() * hedef);
        const percent = ((sonuc / hedef) * 100).toFixed(2);
        return { date, hedef, sonuc, percent };
      }
      return { date, hedef: null, sonuc: null, percent: null };
    });
  };

  const calendarData = generateCalendarData();

  const handlePeriodChange = (e: any) => setPeriod(e.target.value);
  const handleHeatmapYearChange = (e: any) => setHeatmapYear(e.target.value);
  const handleChartTypeChange = (e: any) => setChartType(e.target.value);

  const chartData = {
    daily: Array.from({ length: 60 }, (_, i) => ({
      date: dayjs("2024-11-17").subtract(i, "day").format("DD MMM YYYY"),
      hedef: Math.floor(Math.random() * 20) + 80,
      sonuc: Math.floor(Math.random() * 20) + 60,
      ofis: Math.floor(Math.random() * 20) + 70,
    })),
    monthly: Array.from({ length: 12 }, (_, i) => ({
      date: dayjs().subtract(i, "month").format("MMM YYYY"),
      hedef: Math.floor(Math.random() * 100) + 800,
      sonuc: Math.floor(Math.random() * 100) + 700,
      ofis: Math.floor(Math.random() * 100) + 750,
    })),
    yearly: Array.from({ length: 5 }, (_, i) => ({
      date: dayjs().subtract(i, "year").format("YYYY"),
      hedef: Math.floor(Math.random() * 1000) + 8000,
      sonuc: Math.floor(Math.random() * 1000) + 7000,
      ofis: Math.floor(Math.random() * 1000) + 7500,
    })),
  };

  const currentChartData = chartData[period];

  const showModal = (data: any) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const startDate = dayjs("2024-01-01");
  const today = dayjs();
  const totalDays = today.diff(startDate, "day") + 1;

  const heatmapData = Array.from({ length: totalDays }, (_, i) => {
    const currentDate = startDate.add(i, "day").format("YYYY-MM-DD");
    return {
      date: currentDate,
      count: Math.floor(Math.random() * 30) + 20,
    };
  });

  return (
    <Wrapper>
      <Radio.Group value={period} onChange={handlePeriodChange} style={{ marginBottom: "16px", display: "flex", justifyContent: "center" }}>
        <Radio.Button value="daily">Günlük</Radio.Button>
        <Radio.Button value="monthly">Aylık</Radio.Button>
        <Radio.Button value="yearly">Yıllık</Radio.Button>
      </Radio.Group>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col span={24}>
          <Alert
            message={<Typography.Text>👏 Harika bir iş çıkardın! Şu anda %75 başarı oranına sahipsin. Bugünkü hedefini girmek için <Typography.Link href="/target/create">hemen tıkla</Typography.Link>.</Typography.Text>}
            type="success"
            showIcon
            banner
            style={{ textAlign: "center" }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={6} md={6} lg={6} xl={6}>
          <Card bordered onClick={showGaugeModal}>
            <GaugeChart id="gauge-chart" nrOfLevels={10} percent={gaugeData.sonuc / gaugeData.hedef} textColor="#000" />
            <Typography.Title level={5} style={{ textAlign: "center", marginTop: "16px" }}>Hedef Gerçekleşme</Typography.Title>
            <p style={{ textAlign: "center" }}>{((gaugeData.sonuc / gaugeData.hedef) * 100).toFixed(2)}%</p>
            <p style={{ textAlign: "center" }}>Hedef: {gaugeData.hedef} - Sonuç: {gaugeData.sonuc}</p>
          </Card>
        </Col>
        <Col xs={24} sm={18} md={18} lg={18} xl={18}>
          <Card bordered>
            <Radio.Group value={heatmapYear} onChange={handleHeatmapYearChange} style={{ marginBottom: "16px", display: "flex", justifyContent: "center" }}>
              <Radio.Button value="thisYear">Bu Yıl</Radio.Button>
              <Radio.Button value="lastYear">Geçen Yıl</Radio.Button>
            </Radio.Group>
            <HeatMap
              value={heatmapData}
              rectSize={16}
              startDate={new Date("2024-01-01")}
              width={1000}
              height={205}
              monthLabels={["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]}
              weekLabels={["Pz", "Pzt", "Sal", "Çrş", "Prş", "Cum", "Cmt"]}
              panelColors={{
                0: "#e0f7ff",
                20: "#b3e0ff",
                40: "#80c7ff",
                60: "#4d9eff",
                80: "#3f9ffb",
                100: "#1a75ff",
              }}
              rectRender={(props, data) => {
                const date = dayjs(data.date);
                const formattedDate = date.format("D MMMM YYYY, dddd");
                return (
                  <Tooltip
                    title={() => {
                      const randomHedef = Math.floor(Math.random() * 30) + 20;
                      const randomSonuc = data.count || Math.floor(Math.random() * randomHedef);
                      const successRate = ((randomSonuc / randomHedef) * 100).toFixed(2);
                      return (
                        <>
                          <div><strong>Tarih:</strong> {formattedDate}</div>
                          <div><strong>Hedef:</strong> {randomHedef}</div>
                          <div><strong>Sonuç:</strong> {randomSonuc}</div>
                          <div><strong>Başarı:</strong> {successRate}%</div>
                        </>
                      );
                    }}
                  >
                    <rect {...props} onClick={() => showModal(data)} />
                  </Tooltip>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Card bordered>
            <Radio.Group value={chartType} onChange={handleChartTypeChange} style={{ marginBottom: "16px", display: "flex", justifyContent: "center" }}>
              <Radio.Button value="line">Çizgi</Radio.Button>
              <Radio.Button value="bar">Sütun</Radio.Button>
            </Radio.Group>
            <ReactApexChart
              type={chartType}
              series={[
                { name: "Hedef", data: currentChartData.map((d) => d.hedef) },
                { name: "Sonuç", data: currentChartData.map((d) => d.sonuc) },
                { name: "Ofis Ortalaması", data: currentChartData.map((d) => d.ofis) },
              ]}
              options={{
                chart: { id: "chart", toolbar: { show: false }, zoom: { enabled: false } },
                xaxis: { categories: currentChartData.map((d) => d.date) },
                stroke: { curve: "smooth" },
              }}
              height={250}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Card bordered>
            <Calendar
              dateCellRender={(date) => {
                const formattedDate = date.format("YYYY-MM-DD");
                const entry = calendarData.find((d) => d.date === formattedDate);
                if (!entry) return null;
                if (entry.hedef === null) return <Tag color="red">Hedef Girilmedi</Tag>;
                return (
                  <Tag
                    color="geekblue"
                    onClick={() => showModal(entry)}
                    style={{ cursor: "pointer" }}
                  >
                    Hedef: {entry.hedef} <br />
                    Sonuç: {entry.sonuc} <br />
                    Başarı: {entry.percent}%
                  </Tag>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Detaylı Bilgiler"
        visible={isModalOpen}
        onCancel={closeModal}
        footer={null}
      >
        <Table
          dataSource={[
            { key: "1", name: "Tarih", value: modalData?.date },
            { key: "2", name: "Sonuç", value: modalData?.count || 0 },
          ]}
          columns={[
            { title: "Alan", dataIndex: "name", key: "name" },
            { title: "Değer", dataIndex: "value", key: "value" },
          ]}
          pagination={false}
        />
        <br />
        <Table
          dataSource={dataDetailTable}
          columns={columnsDetailTable}
          pagination={false}
          bordered
        />
      </Modal>
      <Modal
        title="Detaylı Hedef Bilgisi"
        visible={isGaugeModalOpen}
        onCancel={closeGaugeModal}
        footer={null}
      >
        {gaugeModalData.length > 0 && (
          <div>
            {gaugeModalData.map((item) => (
              <div key={item.key} style={{ marginBottom: "20px" }}>
                <GaugeChart
                  id={`gauge-chart-modal-${item.key}`}
                  nrOfLevels={10}
                  percent={item.sonuc / 100}
                  textColor="#000"
                />
                <Typography.Title level={5} style={{ textAlign: "center", marginTop: "16px" }}>
                  {item.hedef}
                </Typography.Title>
                <p style={{ textAlign: "center" }}>Sonuç: {item.sonuc}</p>
                <p style={{ textAlign: "center" }}>Başarı Yüzdesi: {item.basari}</p>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </Wrapper>
  );
};

export default Dashboard;

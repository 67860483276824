import axios from "axios";
import React, { SyntheticEvent, useEffect, useState, useRef } from "react";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";

import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import CustomInput from "../../../components/form/customInput";
import CustomSunEditor from "../../../components/form/sun-editor/customSunEditor";
import { useUserContext } from "../../../providers/user/userProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { User } from "../../../models/user";
import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { IdType } from "../../../models/attribute/idType/idType";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Country } from "../../../models/general/country/country";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message, TreeSelect } from 'antd';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { setKey, fromAddress } from "react-geocode";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import moment from 'moment';
import dayjs from 'dayjs';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
//Boostrap
import { Modal, Button } from "react-bootstrap";
import { Asset } from "../../../models/contract/asset/asset";
import { Item } from "../../../models/contract/item/item";
import Property from "../../../components/property/property";
import Swal from "sweetalert2";
import { PurchaseSaleAgreement } from "../../../models/contract/purchase-sale-agreements/purchaseSaleAgreement";

const { Option } = Select;

interface TreeNode {
    value: string;
    title: string;
    key: string;
    children?: TreeNode[];
}

const Edit = () => {

    //Edit
    const { id } = useParams();
    const [purchase_sale_agreement, setPurchaseSaleAgreement] = useState<PurchaseSaleAgreement | null>(null);

    //General
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [users, setUsers] = useUserContext();
    const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [id_types, setIdTypes] = useIdTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();

    const [selected_agent, setSelectedAgent] = useState<any | null>(null);
    const [partner_count, setPartnerCount] = useState<any | null>(0);
    const [selected_agents, setSelectedAgents] = useState([]);
    const [transaction_types, setTransactionTypes] = useState([]);

    const [asset_name, setAssetName] = useState<any | null>('');

    //Document
    const [status_id, setStatusId] = useState<any | null>(null);
    const [statuses, setStatuses] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [payment_types, setPaymentTypes] = useState([]);
    const [selected_payment_types, setSelectedPaymentTypes] = useState<{ value: string, label: React.ReactNode, price?: number, currency_id?: number, currency: any }[]>([]);
    const [commercial_auth_number, setCommercialAuthNumber] = useState<any | null>('');


    // Seller
    const [seller_customer_type_id, setSellerCustomerTypeId] = useState(1);
    const [seller_company_name, setSellerCompanyName] = useState('');
    const [seller_tax_admin, setSellerTaxAdmin] = useState('');
    const [seller_tax_number, setSellerTaxNumber] = useState('');
    const [seller_phone_code_id, setSellerPhoneCodeId] = useState(34);
    const [seller_first_name, setSellerFirstName] = useState<any | null>('');
    const [seller_last_name, setSellerLastName] = useState<any | null>('');
    const [seller_id_type_id, setSellerIdTypeId] = useState(3);
    const [seller_nationality_id, setSellerNationalityId] = useState(34);
    const [seller_id_no, setSellerIdNo] = useState('');
    const [seller_birthday, setSellerBirthday] = useState('');
    const [seller_email, setSellerEmail] = useState('');
    const [seller_address, setSellerAddress] = useState('');
    const [seller_phone, setSellerPhone] = useState('');
    const [seller_note, setSellerNote] = useState('');
    const [seller_bank_name, setSellerBankName] = useState('');
    const [seller_iban, setSellerIban] = useState('');

    // Buyer
    const [buyer_customer_type_id, setBuyerCustomerTypeId] = useState(1);
    const [buyer_company_name, setBuyerCompanyName] = useState('');
    const [buyer_tax_admin, setBuyerTaxAdmin] = useState('');
    const [buyer_tax_number, setBuyerTaxNumber] = useState('');
    const [buyer_phone_code_id, setBuyerPhoneCodeId] = useState(34);
    const [buyer_first_name, setBuyerFirstName] = useState('');
    const [buyer_last_name, setBuyerLastName] = useState('');
    const [buyer_nationality_id, setBuyerNationalityId] = useState(34);
    const [buyer_id_type_id, setBuyerIdTypeId] = useState(3);
    const [buyer_id_no, setBuyerIdNo] = useState('');
    const [buyer_birthday, setBuyerBirthday] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [buyer_address, setBuyerAddress] = useState('');
    const [buyer_phone, setBuyerPhone] = useState('');
    const [buyer_note, setBuyerNote] = useState('');

    //Property
    const [province_id, setProvinceId] = useState<any | null>(7);
    const [province_name, setProvinceName] = useState<any | null>('');
    const [district_id, setDistrictId] = useState<any | null>(2038);
    const [district_name, setDistrictName] = useState<any | null>('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(2338);
    const [neighbourhood_hsb_id, setNeighbourhoodHsbId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState<any | null>('');
    const [csb_floor_id, setCsbFloorId] = useState<any | null>(0);
    const [csb_parcel_id, setCsbParcelId] = useState<any | null>(0);
    const [rectangle_coordinates, setRectangleCoordinates] = useState<any | null>('');
    const [property_id, setPropertyId] = useState<any | null>(0);
    const [propertyData, setPropertyData] = useState<any | null>(null);


    const [site_search, setSiteSearch] = useState<any | null>('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);


    //Contract
    const [transaction_type_id, setTransactionTypeId] = useState(null);
    const [contract_id, setContractId] = useState(7);
    const [contract_type_id, setContractTypeId] = useState(7);
    const [last_date_time, setLastDateTime] = useState<any | null>(null);
    const [contract_date, setContractDate] = useState<any | null>(null);
    const [start_date, setStartDate] = useState<any | null>(null);

    const [end_date, setEndDate] = useState<any | null>(null);
    const [lease_time, setLeaseTime] = useState<any | null>(null);
    const [per_month_price, setPerMonthPrice] = useState<any | null>(null);
    const [per_year_price, setPerYearPrice] = useState<any | null>(null);
    const [deposit_price, setDepositPrice] = useState<any | null>(null);
    const [deposit_currency_id, setDepositCurrencyId] = useState(259);
    const [buyer_service_fee, setBuyerServiceFee] = useState<any | null>(null);
    const [buyer_service_fee_currency_id, setBuyerServiceFeeCurrencyId] = useState(259);

    const [sale_price, setSalePrice] = useState<any | null>(null);
    const [sale_currency_id, setSaleCurrencyId] = useState(259);


    //Other
    const [get_coordinate, setGetCoordinate] = useState(false);
    const [coordinate_info, setCoordinateInfo] = useState([]);
    const [note_see, setCustomerNoteSee] = useState(false);
    const [seller_note_see, setSellerNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);

    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Güncelle');

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`contract/purchase-sale-agreements/${id}`);
                setPurchaseSaleAgreement(data);
                console.log(data.sale_price);
                //Seller
                setSellerCustomerTypeId(data?.seller.customer_type_id);
                setSellerCompanyName(data?.seller.company_name !== '0' ? data?.seller.company_name : '');
                setSellerTaxAdmin(data?.seller.tax_admin !== '0' ? data?.seller.tax_admin : '');
                setSellerTaxNumber(data?.seller.tax_number !== '0' ? data?.seller.tax_number : '');
                setSellerFirstName(data?.seller.first_name);
                setSellerLastName(data?.seller.last_name);
                setSellerNationalityId(data?.seller.nationality_id);
                setSellerIdTypeId(data?.seller.idType.id);
                setSellerIdNo(data?.seller.id_no);
                setSellerPhoneCodeId(data?.seller.phone_code_id);
                setSellerPhone(data?.seller.phone);
                setSellerEmail(data?.seller.email !== '0' ? data?.seller.email : '');
                setSellerBirthday(dayjs(data?.seller.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setSellerAddress(data?.seller.address);
                (data?.seller.definition !== null && data?.seller.definition !== undefined && data?.seller.definition !== '0') ? setSellerNoteSee(true) : setSellerNoteSee(false);
                setSellerNote(data?.seller.definition);

                //Buyer
                setBuyerCustomerTypeId(data?.buyer.customer_type_id);
                setBuyerCompanyName(data?.buyer.company_name !== '0' ? data?.buyer.company_name : '');
                setBuyerTaxAdmin(data?.buyer.tax_admin !== '0' ? data?.buyer.tax_admin : '');
                setBuyerTaxNumber(data?.buyer.tax_number !== '0' ? data?.buyer.tax_number : '');
                setBuyerFirstName(data?.buyer.first_name);
                setBuyerLastName(data?.buyer.last_name);
                setBuyerNationalityId(data?.buyer.nationality_id);
                setBuyerIdTypeId(data?.buyer.idType.id);
                setBuyerIdNo(data?.buyer.id_no);
                setBuyerPhoneCodeId(data?.buyer.phone_code_id);
                setBuyerPhone(data?.buyer.phone);
                setBuyerEmail(data?.buyer.email !== '0' ? data?.buyer.email : '');
                setBuyerBirthday(dayjs(data?.buyer.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                setBuyerAddress(data?.buyer.address);
                (data?.buyer.definition !== null && data?.buyer.definition !== undefined && data?.buyer.definition !== '0') ? setBuyerNoteSee(true) : setBuyerNoteSee(false);
                setBuyerNote(data?.buyer.definition);
                //Property
                if (data && data.property) {
                    setPropertyData(data.property);
                }

                setContractDate(data?.contract_date);
                setSalePrice(data?.sale_price);
                setSaleCurrencyId(data?.sale_currency?.id);
                setDepositPrice(data?.deposit_price);
                setDepositCurrencyId(data?.deposit_currency?.id);
                setBuyerServiceFee(data?.buyer_service_fee);
                setBuyerServiceFeeCurrencyId(data?.buyer_service_fee_currency?.id);
                const formattedDate = data?.last_date_time ? dayjs(data?.last_date_time, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm') : null;

                setLastDateTime(formattedDate);
                setCommercialAuthNumber(data?.commercial_auth_number);
                setContractDate(data?.contract_date);
                // Verinin ödeme türlerini alın

                const formattedData = data?.payment_type.map((payment: any) => ({
                    value: payment.meta.id.toString(),
                    label: payment.meta.name,
                    price: payment.price,
                    currency_id: payment.currency.id
                }));
                setSelectedPaymentTypes(formattedData);

                setStatusId(data?.status?.id);
                setContent(data?.content);
            }
        )()
    }, [id]);

    const [download_word, setDownloadWord] = useState<any | null>(true);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/11');

                setTransactionTypes(data.data);
            }
        )()
    }, []);



    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/59');

                setStatuses(data.data);
            }
        )()
    }, []);



    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/49');

                setCurrencies(data.data);
            }
        )()
    }, []);



    useEffect(() => {
        transaction_type_id == 116 ? setContractId(7) : setContractId(8);
    }, [transaction_type_id])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])


    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');


    useEffect(() => {
        if (start_date != null && end_date != null) {
            const diff = moment(end_date).diff(start_date, 'months');
            let lTimeDiff = diff >= 12 && diff > 0
                ? `${Math.floor(diff / 12)} Yıl ${(diff % 12 == 0) ? '' : diff % 12 + ' Ay'}`
                : `${diff} Ay`;

            setLeaseTime(lTimeDiff);
        }
    }, [start_date, end_date]);

    useEffect(() => {

        setPerYearPrice(per_month_price * 12);
    }, [per_month_price]);

    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Realtor Modal
    const [show_realtor, setShowRealtor] = useState(false);
    const handleClose = () => setShowRealtor(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShowRealtor(true);
            }
        )()
    };

    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };


    const containerStyle = {
        width: '100%',
        height: '400px'
    };




    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('contract/contract-templates/9');

                setContent(data.content);
            }
        )()
    }, [transaction_type_id]);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');

        let save_id = await axios.put(`contract/purchase-sale-agreements/${id}`, {
            contract_id,
            transaction_type_id,
            selected_agents,
            partner_count,
            seller_customer_type_id,
            seller_company_name,
            seller_tax_admin,
            seller_tax_number,
            seller_first_name,
            seller_last_name,
            seller_email,
            seller_phone_code_id,
            seller_phone,
            seller_nationality_id,
            seller_id_type_id,
            seller_id_no,
            seller_birthday,
            seller_address,
            seller_bank_name,
            seller_iban,
            seller_note,

            buyer_customer_type_id,
            buyer_company_name,
            buyer_tax_admin,
            buyer_tax_number,
            buyer_first_name,
            buyer_last_name,
            buyer_email,
            buyer_phone_code_id,
            buyer_phone,
            buyer_nationality_id,
            buyer_id_type_id,
            buyer_id_no,
            buyer_birthday,
            buyer_address,
            buyer_note,

            propertyData,

            sale_price,
            sale_currency_id,
            deposit_price,
            deposit_currency_id,
            buyer_service_fee,
            buyer_service_fee_currency_id,
            last_date_time,
            commercial_auth_number,
            contract_date,
            selected_payment_types,
            status_id,
            content
        });
        if (download_word) {
            await axios.get(`contract/purchase-sale-agreements/${save_id.data}/download`)
                .then((res: any) => {

                    if (res.data > 0) {
                        window.location.href = `https://lluparapp.remaxaqua.com/contracts/purchase-sale-agreements/documents/${res.data}.docx`;
                        setRedirect(true);
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Hata1',
                            text: 'İşlem sırasında bir hata gerçekleşti.',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Hata2',
                        text: 'İşlem sırasında bir hata gerçekleşti.',
                        showConfirmButton: false,
                        timer: 2500
                    })
                });
        } else {
            setRedirect(true);
        }
    }

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/56/0/attributeMeta');
                const processedData: any = processData(data);
                setPaymentTypes(processedData);
            }
        )()
    }, []);

    const processData = (data: Attribute[]): TreeNode[] => {
        const treeData: TreeNode[] = [];

        data.forEach((item: any) => {
            const parentItem: any = {
                value: item.id.toString(), // Ana öğenin benzersiz ID'si
                title: item.name,
                key: item.id.toString(),
            };

            if (item.attribute_meta.length > 0) {
                parentItem.children = [];

                item.attribute_meta.forEach((child: any, index: any) => {
                    const childItem: any = {
                        value: child.child.id.toString(), // Çocuk öğenin benzersiz ID'si
                        title: child.child.name,
                        key: child.child.id.toString(),
                    };
                    parentItem.children.push(childItem);
                });
            }

            treeData.push(parentItem);
        });
        return treeData;
    };


    const handleTagRender = (props: any) => {
        const { label, value, closable, onClose } = props;

        const getTitle = (nodeValue: any): any => {
            const node: any = payment_types.find((item: any) => item.value === nodeValue);

            if (node) {
                const titles: string[] = [];
                let currentNode: any | undefined = node;
                while (currentNode) {
                    titles.unshift(currentNode.title);
                    currentNode = payment_types.find((item: any) => item.value === currentNode?.key.split('_')[1]);
                }
                return titles.join(' -> ');
            }
            return label || '';
        };

        return (
            <a className="btn btn-light ms-2">
                <span title={getTitle(value)}>{getTitle(value)}</span>
                {closable && (
                    <span onClick={(e) => { e.stopPropagation(); onClose(); }}> ×</span>
                )}
            </a>
        );
    };

    if (redirect) {
        return <Navigate to={'/contract/purchase-sale-agreements'} />;
    }

    const brItems: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Alım Satım Protokolü Sözleşmeleri', path: '/contract/purchase-sale-agreements' },
        { label: 'Güncelle', active: true },
    ];
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Alım Satım Protokolü Sözleşmesi Güncelle</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={brItems} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="row align-items-end">
                                    <div className="col-12 col-lg-6">
                                        <div className="d-flex align-items-end mt-0 mt-lg-0">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-xl me-3">
                                                    <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" alt="" style={{ width: 100 }} className="img-fluid rounded-circle d-block img-thumbnail" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    <h5 className="font-size-16 mb-1">{purchase_sale_agreement?.agent.first_name} {purchase_sale_agreement?.agent.last_name}</h5>
                                                    <p className="text-muted font-size-13 mb-1 pb-0"><strong>Sözleşme Türü : </strong> {purchase_sale_agreement?.contract?.name}</p>
                                                    <p className="text-muted font-size-13 mb-1 pb-0"><strong>Ortak Sayısı : </strong> {purchase_sale_agreement?.partner_count}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="mt-2 mt-lg-0">
                                            <p className="text-muted font-size-13 mb-1"><strong>İşlem Tarihi : </strong> {purchase_sale_agreement?.created_at}</p>
                                            <p className="text-muted font-size-13 mb-1 pb-0"><strong>Ekleyen / Güncelleyen Personel : </strong> {purchase_sale_agreement?.user.first_name} {purchase_sale_agreement?.user.last_name}</p>
                                            <p className="text-muted font-size-13 mb-1 pb-0"><strong>IP : </strong> {purchase_sale_agreement?.ip}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Satıcı/Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Satıcı Bilgileri</label>
                                                <div>
                                                    <Radio.Group defaultValue={seller_customer_type_id} value={seller_customer_type_id} onChange={e => setSellerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerCompanyName(e.target.value)}
                                                    value={seller_company_name}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxAdmin(e.target.value)}
                                                    value={seller_tax_admin}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxNumber(e.target.value)}
                                                    value={seller_tax_number}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    name="seller_first_name"
                                                    className="form-control"
                                                    value={seller_first_name}
                                                    required
                                                    onChange={e => setSellerFirstName(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="seller_last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    value={seller_last_name}
                                                    required
                                                    onChange={e => setSellerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" value={seller_id_type_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Numara</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="seller_id_no"
                                                    value={seller_id_no}
                                                    required
                                                    onChange={e => setSellerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select value={seller_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSellerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="seller_phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " value={seller_phone} onChange={e => setSellerPhone(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={seller_email}
                                                    onChange={e => setSellerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" value={seller_nationality_id} defaultValue={seller_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={seller_birthday}
                                                    onChange={e => setSellerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="seller_address"
                                                    value={seller_address}
                                                    required
                                                    onChange={e => setSellerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { seller_note_see ? setSellerNoteSee(false) : setSellerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={seller_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    value={seller_note}
                                                    onChange={e => setSellerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Alıcı / Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Alıcı Bilgileri</label>
                                                <div>
                                                    <Radio.Group value={buyer_customer_type_id} onChange={e => setBuyerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    value={buyer_company_name}
                                                    onChange={e => setBuyerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    value={buyer_tax_admin}
                                                    onChange={e => setBuyerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    value={buyer_tax_number}
                                                    onChange={e => setBuyerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="buyer_first_name"
                                                    value={buyer_first_name}
                                                    required
                                                    onChange={e => setBuyerFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    name="buyer_last_name"
                                                    value={buyer_last_name}
                                                    required
                                                    onChange={e => setBuyerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" value={buyer_id_type_id} showSearch
                                                    onChange={e => setBuyerIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="buyer_id_no"
                                                    value={buyer_id_no}
                                                    required
                                                    onChange={e => setBuyerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select value={buyer_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setBuyerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="buyer_phone" required value={buyer_phone} onChange={e => setBuyerPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={buyer_email}
                                                    onChange={e => setBuyerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" value={buyer_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setBuyerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={buyer_birthday}
                                                    onChange={e => setBuyerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="buyer_address"
                                                    value={buyer_address}
                                                    required
                                                    onChange={e => setBuyerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { buyer_note_see ? setBuyerNoteSee(false) : setBuyerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={buyer_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    value={buyer_note}
                                                    className="form-control"
                                                    onChange={e => setBuyerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <Property
                                    pageInfo={{
                                        title: 'Mülk Bilgileri'
                                    }}
                                    exportData={(propertyData: any) => {
                                        setPropertyData(propertyData);
                                    }}
                                    initialData={propertyData}
                                />
                                <div>
                                </div>
                            </div>
                        </div>
                        {/* Sözleşme Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-group mb-3">
                                            <label>Sözleşme Bilgileri</label>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Satış Fiyatı</label>
                                            <div className="input-group">
                                                <div className="input-group-text p-0">
                                                    <Select defaultValue={sale_currency_id} value={sale_currency_id} style={{ width: 70 }} showSearch
                                                        optionFilterProp="children"
                                                        onChange={e => setSaleCurrencyId(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >
                                                        {currencies?.map((currencies: Attribute) => {
                                                            return (
                                                                <Option value={currencies.id} title={currencies.name}
                                                                    key={currencies.name}
                                                                    id={currencies.id}>{currencies.name}</Option>
                                                            )
                                                        })}
                                                    </Select>

                                                </div>
                                                <NumericFormat
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={sale_price}
                                                    onChange={e => { setSalePrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Depozito Tutarı</label>
                                            <div className="input-group">
                                                <div className="input-group-text p-0">
                                                    <Select value={deposit_currency_id} style={{ width: 70 }} showSearch
                                                        optionFilterProp="children"
                                                        onChange={e => setDepositCurrencyId(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >
                                                        {currencies?.map((currencies: Attribute) => {
                                                            return (
                                                                <Option value={currencies.id} title={currencies.name}
                                                                    key={currencies.name}
                                                                    id={currencies.id}>{currencies.name}</Option>
                                                            )
                                                        })}
                                                    </Select>

                                                </div>
                                                <NumericFormat
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={deposit_price}
                                                    onChange={e => { setDepositPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Alıcı Hizmet Bedeli</label>
                                            <div className="input-group">
                                                <div className="input-group-text p-0">
                                                    <Select value={buyer_service_fee_currency_id} style={{ width: 70 }} showSearch
                                                        optionFilterProp="children"
                                                        onChange={e => setBuyerServiceFeeCurrencyId(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >
                                                        {currencies?.map((currencies: Attribute) => {
                                                            return (
                                                                <Option value={currencies.id} title={currencies.name}
                                                                    key={currencies.name}
                                                                    id={currencies.id}>{currencies.name}</Option>
                                                            )
                                                        })}
                                                    </Select>

                                                </div>
                                                <NumericFormat
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={buyer_service_fee}
                                                    onChange={e => { setBuyerServiceFee(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3 select-style">
                                            <label>En Geç Tapu Devir Tarihi</label>
                                            <div>
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime
                                                    format={'DD/MM/YYYY HH:mm'}
                                                    locale={locale}
                                                    value={last_date_time ? dayjs(last_date_time, 'YYYY-MM-DD HH:mm') : null}
                                                    onChange={(value, dateString: any) => {
                                                        setLastDateTime(dayjs(dateString, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm"));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3">
                                            <label>Taşınmaz Ticari Yetki Belge No</label>
                                            <input type="text"
                                                className="form-control"
                                                name="commercial_auth_number"
                                                value={commercial_auth_number}
                                                required
                                                onChange={e => setCommercialAuthNumber(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3 select-style">
                                            <label>Sözleşme Tarihi</label>
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                format={'DD/MM/YYYY'}
                                                locale={locale}
                                                value={contract_date ? dayjs(contract_date, 'DD/MM/YYYY') : null}
                                                onChange={(value, dateString) => {
                                                    setContractDate(dateString);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3 select-style">
                                            <label>Ödeme Türü</label>
                                            <TreeSelect
                                                className="form-control"
                                                showSearch
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder="İşlem Türü Seçiniz"
                                                allowClear
                                                multiple
                                                treeDefaultExpandAll
                                                treeData={payment_types}
                                                value={selected_payment_types}
                                                defaultValue={selected_payment_types}
                                                onChange={e => setSelectedPaymentTypes(e)}
                                            // tagRender={handleTagRender}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-md-3">
                                        <div className="form-group mb-3 select-style">
                                            <label>Durum</label>
                                            <Select className="form-control" defaultValue={status_id} showSearch
                                                value={status_id}
                                                placeholder="Sözleşme Durumunu Seçiniz"
                                                onChange={e => setStatusId(e)}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );

                                                }}
                                            >
                                                {statuses?.map((statuses: Attribute) => {
                                                    return (
                                                        <Option value={statuses.id} title={statuses.name}
                                                            key={statuses.name}
                                                            id={statuses.name}>{statuses.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>

                                    {
                                        selected_payment_types
                                            ? selected_payment_types.length > 0 ? selected_payment_types.map((payment_types: any, i) => {
                                                return (
                                                    <>
                                                        <div className="col-xl-2 col-md-2">
                                                            <div className="form-group mb-3">
                                                                <label>{payment_types.label}</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-text p-0">
                                                                        <Select style={{ width: 70 }} defaultValue={payment_types.currency_id} showSearch
                                                                            optionFilterProp="children"
                                                                            onChange={e => {
                                                                                const updatedSelectedPaymentTypes = [...selected_payment_types];
                                                                                updatedSelectedPaymentTypes[i].currency.id = e;
                                                                                setSelectedPaymentTypes(updatedSelectedPaymentTypes);
                                                                            }}
                                                                            filterOption={(input, option) => {
                                                                                return (
                                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                );

                                                                            }}
                                                                        >
                                                                            {currencies?.map((currencies: Attribute) => {
                                                                                return (
                                                                                    <Option value={currencies.id} title={currencies.name}
                                                                                        key={currencies.name}
                                                                                        id={currencies.id}>{currencies.name}</Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                    <NumericFormat
                                                                        className="form-control"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                        defaultValue={payment_types.price}
                                                                        value={payment_types.price}
                                                                        onChange={e => {
                                                                            const updatedSelectedPaymentTypes = [...selected_payment_types];
                                                                            updatedSelectedPaymentTypes[i].price = parseFloat(e.target.value.replace(/[.]/g, '').replace(',', '.'));
                                                                            setSelectedPaymentTypes(updatedSelectedPaymentTypes);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }) : null : null}


                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-group mb-3">
                                            <label>Sözleşme Maddeleri</label>
                                            <CustomSunEditor
                                                height={'400px'}
                                                defaultValue={content}
                                                value={content}
                                                onChange={(e: any) => setContent(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <div className="form-group mb-3">
                                        <Checkbox defaultChecked={download_word} onChange={e => setDownloadWord(e.target.checked)}>Güncelledikten sonra indir</Checkbox>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </Wrapper >
    )
}

export default Edit;
import React, { useState, useMemo, useContext, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Row as AntRow,
  Col,
  Switch,
  Radio,
  Form,
  Input,
  Popconfirm,
} from "antd";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";
import Wrapper from "../../components/layout/wrapper";
import AntDesignAttributeSelect from "../../components/selectors/dataSelectors/antDesign/AntDesignAttributeSelect";
import { Trash2 } from "react-feather";
import useAttributes from "../../api/hooks/attributes/useAttributes";

const { Text } = Typography;

interface Target {
  key: string;
  name: string;
  hedef: number;
  durum: boolean;
}

const RowContext = React.createContext<any>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "grab" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const EditableCell: React.FC<any> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <Input /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Lütfen ${title} giriniz!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Settings: React.FC = () => {
  const [data, setData] = useState<Target[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  const { attributes = [], isLoading } = useAttributes(
    selectedCategoryId !== null
      ? { filter_category_id: selectedCategoryId, limit: 1000 }
      : {}
  );

  const columns = [
    {
      key: "sort",
      align: "center" as const,
      width: 50,
      render: () => <DragHandle />,
    },
    {
      title: "Hedef Adı",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "Minimum Hedef",
      dataIndex: "hedef",
      key: "hedef",
      render: (hedef: number, record: Target) => (
        <Input
          type="number"
          value={hedef}
          onChange={(e) =>
            setData((prevData) =>
              prevData.map((item) =>
                item.key === record.key
                  ? { ...item, hedef: Math.max(0, +e.target.value) }
                  : item
              )
            )
          }
        />
      ),
    },
    {
      title: "Durum",
      dataIndex: "durum",
      key: "durum",
      render: (durum: boolean, record: Target) => (
        <Switch
          checked={durum}
          onChange={(checked) =>
            setData((prevData) =>
              prevData.map((item) =>
                item.key === record.key ? { ...item, durum: checked } : item
              )
            )
          }
        />
      ),
    },
    {
      key: "delete",
      align: "center" as const,
      render: (_: any, record: Target) => (
        <Popconfirm
          title="Emin misiniz? Kalıcı olarak silinecektir."
          onConfirm={() =>
            setData((prevData) => prevData.filter((item) => item.key !== record.key))
          }
          okText="Evet"
          cancelText="Hayır"
        >
          <Button type="text" icon={<Trash2 height={20} />} />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    if (selectedCategoryId !== null) {
      const updatedData = attributes.map((attr: any, index: number) => ({
        key: `${index}`,
        name: attr.name,
        hedef: 0,
        durum: true,
      }));
      setData(updatedData);
    }
  }, [selectedCategoryId, attributes]);

  return (
    <Wrapper>
      <AntRow justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <AntDesignAttributeSelect
            placeholder="Hedef türünü seçiniz"
            onChange={(value: number) => setSelectedCategoryId(value)}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() =>
              setData((prevData) => [
                ...prevData,
                { key: `${prevData.length}`, name: "Yeni Hedef", hedef: 0, durum: true },
              ])
            }
          >
            Yeni Ekle
          </Button>
        </Col>
      </AntRow>
      <DndContext sensors={[useSensor(PointerSensor)]}>
        <SortableContext
          items={data.map((item) => item.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            dataSource={data}
            columns={columns}
            rowKey="key"
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};

export default Settings;

import React, { useEffect, useMemo } from 'react';
import { Skeleton } from 'antd';
import AntDesignSelect from '../../../constant/antDesign/select';
import useAttributes from '../../../../api/hooks/attributes/useAttributes';

interface AntDesignAttributeSelectProps {
  placeholder?: string;
  loadingSelect?: boolean;
  autoFocus?: boolean;
  isMulti?: boolean;
  loadingStatus?: (loading: boolean) => void;
  params?: { [key: string]: any };
  [key: string]: any;
}

const AntDesignAttributeSelect: React.FC<AntDesignAttributeSelectProps> = ({
  placeholder,
  isMulti,
  params,
  loadingSelect,
  loadingStatus = () => {},
  showSearch,
  ...props
}) => {
  const quertyParams = useMemo(() => ({
    filter_types: [66],
    limit: 1000,
  }), []);

  const { attributes, isLoading } = useAttributes(quertyParams);

  const options = useMemo(() => {
    return attributes.map((item) => ({
      label: `${item.name}`,
      value: item.id,
    }));
  }, [attributes]);

  return (
    <AntDesignSelect
      options={options}
      placeholder={placeholder}
      style={{
        width: "200px",
        minWidth: "200px",
        maxWidth: "200px",
      }}
      dropdownStyle={{ width: "200px" }}
      {...props}
    />
  );
};

export default AntDesignAttributeSelect;

import axios from "axios";
import React, { SyntheticEvent, useMemo, useState } from "react";
import {  Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { Checkbox, Radio, Select, Switch } from "antd";
import { NumericFormat } from "react-number-format";
import CustomInput from "../../../components/form/customInput";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import { useExpenseCategoryContext } from "../../../providers/attribute/expenseCategory/expenseCategoryProvider";
import { ZhihuCircleFilled } from "@ant-design/icons";
import { ExpenseCategory } from "../../../models/attribute/expenseCategory/exoenseCategory";
import useAttributes from "../../../api/hooks/attributes/useAttributes";
import { AttributeNew } from "../../../models/attribute/attributeNewData";

const { Option, OptGroup } = Select;

const Create = () => {

    // General
    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');

    const [selected_categories, setSelectedCategories] = useState([]);
    const [user_categories, setUserCategories] = useState([]);
    const [price, setPrice] = useState<any | null>(null);
    const [frequency_id, setFrequencyId] = useState<number | null>(null);
    const [status_id, setStatusId] = useState<number | null>(151);
    const [category_id, setCategoryId] = useState<number | null>(null);
    const [user_category_id, setUserCategoryId] = useState<number>(0);
    const [due_day, setDueDay] = useState<number | null>(null);
    const [payment_date, setPaymentDate] = useState<any | null>(null);
    const [directive, setDirective] = useState<boolean>(false);
    const [definition, setDefinition] = useState<any | null>('');
    const [type, setType] = useState<number>(148);
    const [directive_type, setDirectiveType] = useState<number>(148);
    const [table_name, setTableName] = useState<string>('cashbooks');

    const [redirect, setRedirect] = useState(false);


    const params = useMemo(() => ({
        filter_types: [19],
        limit:1000
      }), []);
    
      const { attributes, isLoading } = useAttributes(params);
    
      const frequencies = attributes.filter((attr: AttributeNew) => attr.type === 19);

    const [expense_categories, setExpenseCategories] = useExpenseCategoryContext();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');

        await axios.post('cashbooks', {
            category_id,
            price,
            frequency_id,
            payment_date,
            due_day,
            definition,
            status_id,
            type,
            directive_type,
            table_name,
            directive
        });
        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to={'/accounting/expenses'} />;
    }
    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Giderler', path: '/accounting/expenses' },
        { label: 'Oluştur', active: true },
    ];

    interface ExpenseCategory {
        id: number;
        name: string;
        category: Attribute[];
    }

    interface Attribute {
        id: number;
        name: string;
    }

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Gider Ekle</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kategori</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Kategori Seçiniz"
                                                    onChange={e => { setCategoryId(e); }}
                                                    optionFilterProp="children"
                                                    value={category_id}
                                                    defaultValue={category_id}
                                                    onSearch={value => {
                                                        const lowercaseValue = value.toLowerCase();
                                                        const filteredOptions = expense_categories.map((parent_category: any) => {
                                                            const filteredCategory = parent_category.category.filter((child_category: any) => {
                                                                return child_category.name.toLowerCase().includes(lowercaseValue);
                                                            });
                                                            return { ...parent_category, category: filteredCategory };
                                                        });
                                                        return filteredOptions;
                                                    }}
                                                >
                                                    {expense_categories.map((parent_category: any) => (
                                                        <OptGroup label={parent_category.name} key={parent_category.id}>
                                                            {parent_category.category.map((child_category: any) => (
                                                                <Option value={child_category.id} title={child_category.name} key={child_category.id}>
                                                                    {child_category.name}
                                                                </Option>
                                                            ))}
                                                        </OptGroup>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Tutar</label>
                                                <NumericFormat
                                                    value={price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    onChange={e => { setPrice(e.target.value); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Tarih</label>
                                                <CustomInput
                                                    type={'date'}
                                                    required={true}
                                                    onChange={(e: any) => setPaymentDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 mt-4 pt-2">
                                                <Checkbox defaultChecked={directive} onChange={e => setDirective(e.target.checked)}>İşlem emri ekle</Checkbox>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={directive ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3 select-style">
                                                <label>İşlem Sıklığı</label>
                                                <Select className="form-control" showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setFrequencyId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {/* {frequencies.map((frequencies: Attribute) => {
                                                        return (
                                                            <Option value={frequencies.id} title={frequencies.name}
                                                                key={frequencies.name}
                                                                id={frequencies.name}>{frequencies.name}</Option>
                                                        )
                                                    })} */}

                                                     {frequencies.map((attr: AttributeNew) => {
                                                        return (
                                                            <Option value={attr.id} title={attr.name}
                                                                key={attr.name}
                                                                id={attr.name}>{attr.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={directive ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>İşlem Günü</label>
                                                <CustomInput
                                                    type={'number'}
                                                    onChange={(e: any) => setDueDay(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Açıklama</label>
                                                <CustomInput
                                                    type={'textarea'}
                                                    onChange={(e: any) => setDefinition(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Ödeme Durumu</label>
                                                <Switch defaultChecked checkedChildren="Ödendi" className="ms-2" unCheckedChildren="Ödenmedi" onChange={(e: any) => e ? setStatusId(151) : setStatusId(152)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Create;
import React, { useState } from "react";
import { Table, InputNumber, Button, Typography, Radio, Input } from "antd";
import Wrapper from "../../components/layout/wrapper";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;

interface TargetData {
  name: string;
  hedef: number;
  sonuc: number;
  note: string;
}

const Create: React.FC = () => {
  const targets = [
    "İşleme Döndürme",
    "Portföy Oluşturma",
    "Fsbo Araması",
    "Etki Çevresi Geliştirme",
    "Uzmanlık Bölgesi Araştırması",
  ];

  const [data, setData] = useState<TargetData[]>(
    targets.map((target) => ({
      name: target,
      hedef: 2,
      sonuc: 0,
      note: "",
    }))
  );

  const [selectedPeriod, setSelectedPeriod] = useState<string>("daily");

  const screens = useBreakpoint();

  const handleInputChange = (
    index: number,
    field: keyof TargetData,
    value: string | number
  ) => {
    const updatedData = [...data];

    if (field === "note") {
      updatedData[index].note = value as string;
    } else if (field === "hedef" || field === "sonuc") {
      updatedData[index][field] = Math.max(0, Number(value)) as number;
    }

    setData(updatedData);
  };



  const handlePeriodChange = (e: any) => {
    setSelectedPeriod(e.target.value);
  };

  const columns = [
    {
      title: screens.xs ? null : "Hedef Adı",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: TargetData, index: number) => (
        screens.xs ? (
          <div
            style={{
              padding: "16px",
              border: "1px solid #f0f0f0",
              borderRadius: "8px",
              marginBottom: "8px",
              backgroundColor: "#fafafa",
            }}
          >
            <Text
              strong
              style={{
                display: "block",
                marginBottom: "8px",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {record.name}
            </Text>
          </div>
        ) : (
          <Text strong>{text}</Text>
        )
      ),
    },
    {
      title: "Hedef",
      dataIndex: "hedef",
      key: "hedef",
      responsive: ["md" as "md"],
      render: (hedef: number, record: TargetData, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Button
            style={{ width: "36px", height: "36px" }}
            onClick={() => handleInputChange(index, "hedef", hedef - 1)}
          >
            -
          </Button>
          <InputNumber
            style={{ width: "80px" }}
            min={0}
            value={hedef}
            onChange={(value) => handleInputChange(index, "hedef", value || 0)}
          />
          <Button
            style={{ width: "36px", height: "36px" }}
            onClick={() => handleInputChange(index, "hedef", hedef + 1)}
          >
            +
          </Button>
        </div>
      ),
    },
    {
      title: "Sonuç",
      dataIndex: "sonuc",
      key: "sonuc",
      responsive: ["md" as "md"],
      render: (sonuc: number, record: TargetData, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Button
            style={{ width: "36px", height: "36px" }}
            onClick={() => handleInputChange(index, "sonuc", sonuc - 1)}
          >
            -
          </Button>
          <InputNumber
            style={{ width: "80px" }}
            min={0}
            value={sonuc}
            onChange={(value) => handleInputChange(index, "sonuc", value || 0)}
          />
          <Button
            style={{ width: "36px", height: "36px" }}
            onClick={() => handleInputChange(index, "sonuc", sonuc + 1)}
          >
            +
          </Button>
        </div>
      ),
    },
    {
      title: "Not", // Yeni sütun
      dataIndex: "note",
      key: "note",
      render: (note: string, record: TargetData, index: number) => (
        <Input
          value={note}
          placeholder="Not ekle"
          onChange={(e) => handleInputChange(index, "note", e.target.value)}
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <Radio.Group
        value={selectedPeriod}
        onChange={handlePeriodChange}
        style={{ marginBottom: "16px", display: "flex", justifyContent: "center" }}
      >
        <Radio.Button value="daily">Günlük Hedef</Radio.Button>
        <Radio.Button value="monthly">Aylık Hedef</Radio.Button>
        <Radio.Button value="yearly">Yıllık Hedef</Radio.Button>
      </Radio.Group>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.name}
        showHeader={!screens.xs}
        rowClassName={(record, index) => (index % 2 === 0 ? "striped-row" : "")}
      />
      <style>{`
        .striped-row {
          background-color: #f9f9f9;
        }
      `}</style>
    </Wrapper>
  );
};

export default Create;
